/** @module popup */

/**
 * Initializes popup functionalities.
 *
 * @return {Promise<void>} - Resolves immediately, if no popup is on the page, or once functionality is all set up.
 */
export async function popup() {
    if (document.querySelector('#popup--wrapper')) {
        const { initPopup } = await import(/* webpackMode: "lazy" */ './popup.async');

        initPopup();
    }
}
