/** @module application */

import { eventListenerOptions } from '@spinnwerk/polyfills';
import { globalState, liveEvents } from './index';

let passiveHandlerOptions = eventListenerOptions({ passive: true }),
    capturePassiveHandlerOptions = eventListenerOptions({ capture: true, passive: true });

export function handleTabKey() {
    // show focus style only after a tab keystroke
    async function handleFirstTab(event) {
        if ('key' in event && event.key === 'Tab') {
            window.removeEventListener('keydown', handleFirstTab, passiveHandlerOptions);

            if (globalState.has('headroom')) {
                globalState.get('headroom').then((Headroom) => {
                    Headroom.destroy();
                });
            }

            // create keyboard-accessible tooltips from title attributes
            let { Tooltip } = await import(/* webpackMode: "lazy" */ 'foundation-sites/js/foundation.tooltip'),
                elementsWithTitles = new WeakMap(),
                tooltip;

            Foundation.plugin(Tooltip, 'Tooltip');

            document.querySelectorAll('[title]:not([title=""])').forEach((el) => {
                let title = el.title,
                    describedBy = el.getAttribute('aria-describedby');

                elementsWithTitles.set(
                    el,
                    new Foundation.Tooltip(jQuery(el), {
                        disableHover: true,
                        disableForTouch: true,
                        clickOpen: false,
                        alignment: 'center',
                        position: 'top',
                        triggerClass: '', // do not add the .has-tip class to the trigger-element
                    }),
                );

                // Foundation removes the title, but as Tooltips are only used for keyboard-navigation, it is
                // vital to keep the original title, as the mouse may be used in conjunction.
                el.title = title;

                // Foundation also adds aria-describedby-attributes, what can be highly unintended and distracting for
                // AT users, so it also needs to be restored.
                el.setAttribute('aria-describedby', describedBy);
            });

            // Due to the async nature of this implementation, the tooltip would not be shown for the first
            // element, so this must be done manually.
            tooltip = elementsWithTitles.get(document.activeElement);

            if (tooltip) {
                tooltip.show();
            }
        }
    }

    window.addEventListener('keydown', handleFirstTab, passiveHandlerOptions);

    // manage focus visuals for mouse and keyboard differently
    let lastInteraction = null;

    liveEvents().then((LiveEvents) => {
        LiveEvents.addEventListener(
            window,
            'mousedown',
            () => {
                lastInteraction = 'mouse';
            },
            capturePassiveHandlerOptions,
            window,
        )
            .addEventListener(
                window,
                'keydown',
                ({ key }) => {
                    if (key === 'Tab') {
                        lastInteraction = 'tab';
                    }
                },
                capturePassiveHandlerOptions,
                window,
            )
            .addEventListener(
                document,
                'focus',
                () => {
                    if (lastInteraction === 'mouse' || lastInteraction === null) {
                        lastInteraction = 'tab'; // this is a required hack for 'fixing' auto focus in borlabs' cookie-manager
                        document.body.classList.remove('show-focus');
                    } else if (lastInteraction === 'tab') {
                        document.body.classList.add('show-focus');
                    }
                },
                capturePassiveHandlerOptions,
            );
    });

    // if an autofocus element is on the page, hide the focus initially
    requestAnimationFrame(() => {
        if (document.activeElement && lastInteraction === null) {
            document.body.classList.remove('show-focus');
        }
    });

    if (process.env.NODE_ENV !== 'production') {
        document.addEventListener(
            'focus',
            ({ target }) => {
                console.group('[Focus]');
                console.log(target);
                console.groupEnd();
            },
            capturePassiveHandlerOptions,
        );
    }
}
